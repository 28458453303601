import React, { useState, useEffect } from "react";
import styled from "styled-components";
import song from "../img/song.mp3";

const useAudio = (url) => {
  const [audio] = useState(new Audio(song));
  const [playing, setPlaying] = useState(false);

  const toggle = () => setPlaying(!playing);

  useEffect(() => {
    playing ? audio.play() : audio.pause();
  }, [playing]);

  useEffect(() => {
    audio.addEventListener("ended", () => setPlaying(false));
    return () => {
      audio.removeEventListener("ended", () => setPlaying(false));
    };
  }, []);

  return [playing, toggle];
};

const Player = ({ url }) => {
  const [playing, toggle] = useAudio(url);

  return (
    <PlayerStyle button onClick={toggle}>
      {playing ? "Pause" : "Play!"}
    </PlayerStyle>
  );
};

const PlayerStyle = styled.button`
  position: absolute;
  top: 50px;
  left: 50px;
  color: white;
  background-color: transparent; /* Green */
  border: none;

  font-family: ZCOOL KuaiLe;
  font-style: normal;
  font-weight: normal;
  font-size: 3rem;
  line-height: 3rem;

  :hover {
    color: #3f88c5;
  }
`;

export default Player;
