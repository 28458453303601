import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import kaas from "../img/kaas.png";

import { init, sendForm } from "emailjs-com";
init("user_oZgA9P72sJJXo0qctjDiD");

const Form = () => {
  const [contactNumber, setContactNumber] = useState("000000");

  const generateContactNumber = () => {
    const numStr = "000000" + ((Math.random() * 1000000) | 0);
    setContactNumber(numStr.substring(numStr.length - 6));
  };

  const { register, handleSubmit, watch, errors } = useForm();

  const [status, setStatus] = useState(undefined);

  const onSubmit = (data) => {
    console.log(data);

    generateContactNumber();
    sendForm("service_f89qqt1", "template_fmt78np", "#contact-form").then(
      function (response) {
        console.log("SUCCESS!", response.status, response.text);
        setStatus({ type: "success" });
      },
      function (error) {
        console.log("FAILED...", error);
        setStatus({ type: "error", error });
      }
    );
  };

  return (
    <>
      {status?.type === undefined && (
        <>
          <FormS>
            <h1>Almost there!</h1>
            <form id="contact-form" onSubmit={handleSubmit(onSubmit)}>
              <input
                type="text"
                ref={register}
                placeholder="Name"
                {...register("user-name", { required: true })}
              />
              <br />
              <input
                type="email"
                {...register("user-email", { required: true })}
                placeholder="Email"
              />
              <br />
              <textarea
                {...register("message", { required: true })}
                placeholder="Special requests?"
              />
              <br />
              <input type="submit" value="Send" />
              <input
                type="hidden"
                name="contact_number"
                value={contactNumber}
              />
            </form>
          </FormS>
        </>
      )}
      {status?.type === "success" && (
        <Succes>
          <h1>Sucess!</h1>
          <p>
            Make sure to put on you cheesiest outfit and see you at the
            partayyyyyy!
          </p>
          <img src={kaas}></img>
        </Succes>
      )}
      {status?.type === "error" && (
        <FormS>
          <h2>Something went wrong, try again!</h2>
        </FormS>
      )}
    </>
  );
};

const FormS = styled.div`
  form {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    input[type="submit"] {
      background-color: #ffd025;

      height: 2em;
      text-align: center;
      font-family: ZCOOL KuaiLe;
      font-style: normal;
      font-weight: normal;
      font-size: 50px;
      color: white;
      border: 5px solid white;
      padding: 1rem 2rem;
      cursor: pointer;
      margin-bottom: 5rem;

      :hover {
        color: #ffd025;
        background-color: white;
        border: 5px solid #ffd025;
      }

      @media only screen and (max-width: 900px) {
        font-size: 20px;
      }
    }

    input {
      height: 1.3em;
      text-align: center;
      font-family: ZCOOL KuaiLe;
      font-style: normal;
      font-weight: normal;
      font-size: 50px;

      color: black;
      border: 5px solid #a1d4fd;

      @media only screen and (max-width: 900px) {
        font-size: 20px;
      }
    }
    textarea {
      height: 5em;
      text-align: center;
      font-family: ZCOOL KuaiLe;
      font-style: normal;
      font-weight: normal;
      font-size: 50px;
      border: 5px solid #a1d4fd;

      color: black;
      @media only screen and (max-width: 900px) {
        font-size: 20px;
      }
    }
  }
  p {
    height: 1.3em;
    text-align: center;
    font-family: ZCOOL KuaiLe;
    font-style: normal;
    font-weight: normal;
    font-size: 50px;

    color: white;
  }
  h1 {
    margin: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;

    text-align: center;
    font-family: ZCOOL KuaiLe;
    font-style: normal;
    font-weight: normal;
    font-size: 150px;
    line-height: 220px;
    color: white;
    @media only screen and (max-width: 900px) {
      margin-top: 10rem;
      font-size: 60px;
      line-height: 100px;
    }
  }
  h2 {
    font-size: 50px;
    color: red;
    height: 1em;
  }
`;

const Succes = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  h1 {
    margin-top: 15rem;
    margin-bottom: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;

    text-align: center;
    font-family: ZCOOL KuaiLe;
    font-style: normal;
    font-weight: normal;
    font-size: 150px;
    line-height: 150px;
    color: white;
    @media only screen and (max-width: 900px) {
      margin-top: 7rem;
      margin-bottom: 0rem;
      font-size: 100px;
      line-height: 200px;
    }
  }

  p {
    padding: 0rem 20rem;
    text-align: center;
    font-family: ZCOOL KuaiLe;
    font-style: normal;
    font-weight: normal;
    font-size: 50px;

    color: white;
    @media only screen and (max-width: 900px) {
      font-size: 40px;
      line-height: 40px;
      padding: 0rem 5rem;
    }
  }

  img {
    padding: 5rem;

    @keyframes example {
      0% {
        transform: rotate(50deg);
      }
      50% {
        transform: rotate(-50deg);
      }
      100% {
        transform: rotate(50deg);
      }
    }

    animation-name: example;
    animation-duration: 4s;
    animation-iteration-count: infinite;
  }
`;

export default Form;
