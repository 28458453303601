import styled from "styled-components";
import React from "react";
import Bouncing from "./components/Bouncing";
import Player from "./components/Player";
import Form from "./components/Form";
import GlobalStyle from "./components/GlobalStyle";
import cheese from "./img/cheese.png";
import button from "./img/button.svg";
import titleMarking from "./img/title-marking.svg";

import { Link, Switch, Route } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <GlobalStyle />

      <Player
        url={"https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3"}
      />

      <Switch>
        <Route path="/" exact>
          <Title>Queso Loca!</Title>
          <TitleMarking>
            <img src={titleMarking}></img>
          </TitleMarking>
          <Button>
            <Link className="link" to="/signup">
              Sign Up!
            </Link>
            <img src={button}></img>
          </Button>
          <Cheese src={cheese}></Cheese>
          <Date>
            <h2>&#128198; Sat 3 Juli 2021, 20:00 </h2>
            <h2>&#128205; Franklinplein 5, Eindhoven</h2>
          </Date>
        </Route>

        <Route path="/signup" exact>
          <Signup>
            <Form />
          </Signup>
        </Route>
      </Switch>

      <Bouncing />
    </div>
  );
}

const Signup = styled.div`
  background: #3f88c5;
  width: 100%;
  height: 100vh;
  z-index: -1;
  overflow-y: scroll;
  position: fixed;
`;

const Title = styled.h1`
  h2 {
    font-size: 50px;
    line-height: 50px;
  }
  position: absolute;
  top: 45%;
  left: 50%;
  z-index: -1;
  width: 80%;
  height: 300px;
  margin-top: -150px;
  margin-left: -40%;

  @media only screen and (max-width: 900px) {
    font-size: 100px;
    line-height: 100px;
  }
  @media only screen and (max-width: 1400px) {
    font-size: 120px;
    line-height: 120px;
  }

  text-align: center;
  font-family: ZCOOL KuaiLe;
  font-style: normal;
  font-weight: normal;
  font-size: 160px;
  line-height: 160px;
  color: white;
  z-index: 2;
`;
const Date = styled.h2`
  position: absolute;
  top: 80%;
  left: 20%;
  width: 60%;

  @media only screen and (max-width: 900px) {
    font-size: 20px;
    line-height: 40px;
    width: 90%;
    left: 5%;
  }

  text-align: center;
  font-family: ZCOOL KuaiLe;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 70px;
  color: white;
  z-index: 2;
`;

const TitleMarking = styled.div`
  user-select: none;
  position: absolute;
  top: 45%;
  left: 50%;
  z-index: -1;
  width: 80%;
  height: 300px;
  margin-top: -150px;
  margin-left: -40%;
  z-index: -1;
`;

const Button = styled.div`
  .link {
    position: absolute;
    top: 60%;
    left: 39%;
    z-index: 0;
    width: 50%;
    height: 130px;
    margin-top: -50px;

    color: white;
    background-color: transparent; /* Green */
    border: none;

    font-family: ZCOOL KuaiLe;
    font-style: normal;
    font-weight: normal;
    font-size: 6rem;
    line-height: 7rem;

    cursor: pointer;

    @media only screen and (max-width: 900px) {
      font-size: 60px;
      width: 60%;
      left: 27%;
    }

    :hover {
      color: #234b6b;
    }
  }

  button {
    position: absolute;
    top: 57%;
    left: 25%;
    z-index: 0;
    width: 50%;
    height: 150px;
    margin-top: -50px;

    color: white;
    background-color: transparent; /* Green */
    border: none;

    font-family: ZCOOL KuaiLe;
    font-style: normal;
    font-weight: normal;
    font-size: 7rem;
    line-height: 7rem;

    cursor: pointer;

    @media only screen and (max-width: 900px) {
      font-size: 70px;
      width: 60%;
      left: 20%;
    }

    :hover {
      color: #234b6b;
    }
  }
  img {
    user-select: none;
    position: absolute;
    top: 48%;
    left: 35%;
    z-index: -1;
    width: 30%;
    height: 30%;

    @media only screen and (max-width: 900px) {
      width: 64%;
      left: 18%;
    }
  }
`;

const Cheese = styled.img`
  z-index: -4;
  position: absolute;
  left: 0px;
  top: 25%;
  overflow: hidden;
  user-select: none;

  animation: mymove 50s;

  object-fit: cover;

  @keyframes mymove {
    from {
      left: 0px;
    }
    to {
      left: -3000px;
    }
  }
`;

export default App;
