import styled from "styled-components";
import Matter from "matter-js";
import { Fragment, useEffect, useRef } from "react";

import alex from "../img/alex.png";
import karim from "../img/karim.png";
import lotte from "../img/lotte.png";
import joris from "../img/joris.png";
import camiel from "../img/camiel.png";
import loes from "../img/loes.png";
import wieke from "../img/wieke.png";
import jaap from "../img/jaap.png";

const Bouncing = () => {
  const requestRef = useRef();
  const alexRef = useRef();
  const karimRef = useRef();
  const loesRef = useRef();
  const camielRef = useRef();
  const lotteRef = useRef();
  const jorisRef = useRef();
  const wiekeRef = useRef();
  const jaapRef = useRef();

  const animate = () => {
    const engine = Matter.Engine.create();
    const Mouse = Matter.Mouse;
    const MouseConstraint = Matter.MouseConstraint;

    Matter.MouseConstraint.create(engine);

    const alex = {
      body: Matter.Bodies.rectangle(150, 10, 100, 100, { restitution: 1.4 }),
      elem: alexRef.current,
      render: function () {
        const { x, y } = this.body.position;
        this.elem.style.top = `${y - 20}px`;
        this.elem.style.left = `${x - 20}px`;
        this.elem.style.transform = `rotate(${this.body.angle}rad)`;
      },
    };

    const karim = {
      body: Matter.Bodies.rectangle(400, 0, 100, 100, { restitution: 1.4 }),
      elem: karimRef.current,
      render: function () {
        const { x, y } = this.body.position;
        this.elem.style.top = `${y - 20}px`;
        this.elem.style.left = `${x - 20}px`;
        this.elem.style.transform = `rotate(${this.body.angle}rad)`;
      },
    };

    const lotte = {
      body: Matter.Bodies.rectangle(600, 0, 100, 100, { restitution: 1.4 }),
      elem: lotteRef.current,
      render: function () {
        const { x, y } = this.body.position;
        this.elem.style.top = `${y - 20}px`;
        this.elem.style.left = `${x - 20}px`;
        this.elem.style.transform = `rotate(${this.body.angle}rad)`;
      },
    };

    const camiel = {
      body: Matter.Bodies.rectangle(700, 0, 100, 100, { restitution: 1.4 }),
      elem: camielRef.current,
      render: function () {
        const { x, y } = this.body.position;
        this.elem.style.top = `${y - 20}px`;
        this.elem.style.left = `${x - 20}px`;
        this.elem.style.transform = `rotate(${this.body.angle}rad)`;
      },
    };

    const loes = {
      body: Matter.Bodies.rectangle(900, 0, 100, 100, { restitution: 1.4 }),
      elem: loesRef.current,
      render: function () {
        const { x, y } = this.body.position;
        this.elem.style.top = `${y - 20}px`;
        this.elem.style.left = `${x - 20}px`;
        this.elem.style.transform = `rotate(${this.body.angle}rad)`;
      },
    };

    const joris = {
      body: Matter.Bodies.rectangle(1000, 0, 100, 100, { restitution: 1.4 }),
      elem: jorisRef.current,
      render: function () {
        const { x, y } = this.body.position;
        this.elem.style.top = `${y - 20}px`;
        this.elem.style.left = `${x - 20}px`;
        this.elem.style.transform = `rotate(${this.body.angle}rad)`;
      },
    };

    const wieke = {
      body: Matter.Bodies.rectangle(150, 10, 100, 100, { restitution: 1.4 }),
      elem: wiekeRef.current,
      render: function () {
        const { x, y } = this.body.position;
        this.elem.style.top = `${y - 20}px`;
        this.elem.style.left = `${x - 20}px`;
        this.elem.style.transform = `rotate(${this.body.angle}rad)`;
      },
    };

    const jaap = {
      body: Matter.Bodies.rectangle(1000, 10, 100, 100, { restitution: 1.4 }),
      elem: jaapRef.current,
      render: function () {
        const { x, y } = this.body.position;
        this.elem.style.top = `${y - 20}px`;
        this.elem.style.left = `${x - 20}px`;
        this.elem.style.transform = `rotate(${this.body.angle}rad)`;
      },
    };

    const ground = Matter.Bodies.rectangle(0, 700, 4000, 10, {
      isStatic: true,
    });
    const roof = Matter.Bodies.rectangle(0, -100, 4000, 10, {
      isStatic: true,
    });
    const wallLeft = Matter.Bodies.rectangle(-10, 0, 10, 2000, {
      isStatic: true,
    });
    const wallRight = Matter.Bodies.rectangle(1500, 0, 10, 2000, {
      isStatic: true,
    });

    const mouseConstraint = Matter.MouseConstraint.create(engine, {
      element: document.body,
    });
    Matter.World.add(engine.world, [
      alex.body,
      karim.body,
      loes.body,
      joris.body,
      lotte.body,
      camiel.body,
      wieke.body,
      jaap.body,
      roof,
      ground,
      wallLeft,
      wallRight,
      mouseConstraint,
      Mouse,
    ]);

    (function rerender() {
      alex.render();
      karim.render();
      lotte.render();
      camiel.render();
      joris.render();
      loes.render();
      wieke.render();
      jaap.render();
      Matter.Engine.update(engine);
      requestRef.current = requestAnimationFrame(rerender);
    })();
  };

  useEffect(() => {
    animate();
    return () => cancelAnimationFrame(requestRef.current);
  }, []);

  return (
    <Body>
      <div>
        <Fragment>
          <Button id="alex" ref={alexRef} src={alex}></Button>
          <Button id="karim" ref={karimRef} src={karim}></Button>
          <Button id="loes" ref={loesRef} src={loes}></Button>
          <Button id="camiel" ref={camielRef} src={camiel}></Button>
          <Button id="lotte" ref={lotteRef} src={lotte}></Button>
          <Button id="joris" ref={jorisRef} src={joris}></Button>
          <Button id="wieke" ref={wiekeRef} src={wieke}></Button>
          <Button id="jaap" ref={jaapRef} src={jaap}></Button>
        </Fragment>
      </div>
    </Body>
  );
};

const Button = styled.img`
  position: absolute;
  size: 50%;
  cursor: move;
  z-index: -3;
  user-select: none;
`;

const Body = styled.div`
  height: 100%;
  margin: 0;
  color: yellow;
`;

export default Bouncing;
